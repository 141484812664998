window.validatingEmail = false;
window.emailInvalid = false;
window.espValid = false;
window.overrideEmailValidation = false;

window.validatingPhone = false;
window.phoneInvalid = false;
window.overridePhoneValidation = false;
window.formattedPhoneNumber = "";

let emailEvents = false;
let phoneEvents = false;
let dobEvents = false;
let locationData = false;

let arrayData = {
	collections: "",
	credit_score: "",
	delinquencies: "",
	total_unsecured_debt: ""
}
let verified_debt = 0;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
let autocomplete = {}

window.validEmailTypes = [
    'Verified',
    'Role',
    'Unknown',
    'Catch All',
    'Complainer',
    'Disposable Email',
    'Suppressed'
];
window.espInvalidTypes = [
    'Unknown',
    'Catch All',
    'Complainer',
    'Disposable Email',
    'Suppressed',
    'Bot',
    'Undeliverable',
    'Malformed',
    'SpamTrap'
]

const params = new URLSearchParams(window.location.search)

let postResult = "";
let postMessage = "";
let advertiserID = "";
let postRedirect = "";
let postRevenue = 0;

const advertiserMapping = {
	"4": {
		"buyer": "ClearOne Advantage",
		"url": "clearone",
		"did": "1-888-246-1312"
	},
	"3": {
		"buyer": "Freedom Debt",
		"url": "freedom",
		"did": "1-888-247-6313"
	},
	"2": {
		"buyer": "JG Wentworth",
		"url": "jgwentworth",
		"did": null
	},
	"9": {
		"buyer": "Century Support Services",
		"url": "century",
		"did": null
	},
	"16": {
		"buyer": "NOLO Attorney Network",
		"url": "logo",
		"did": null
	},
	"20": {
		"buyer": "Credit Associates",
		"url": "creditassociates",
		"did": "1-888-458-8159"
	}
}

function emailValidContinue() {
    // If user has proceeded to validation screen, continue
    if (document.getElementById("button-a04b3450")) {
        document.getElementById("button-a04b3450").click()
    }
}

function emailInvalidGoBack() {
    window.overrideEmailValidation = true;
    // If user has proceeded to validation screen, go back
    if (document.getElementById("button-2729fcaa")) {
        document.getElementById("button-2729fcaa").click()
    }
    
    // use a quick timeout to make sure the elements exist already
    window.setTimeout(function() {
        console.log(document.querySelectorAll("[data-variable='email']")[0]);
        document.querySelectorAll("[data-variable='email']")[0].closest('.input').classList.add('error');
    }, 300)
}

function startEmailValidation(email) {
    if (!window.validatingEmail) {
        window.validatingEmail = true;
    
        // first check regex
        console.log(emailRegex.test(email));
        if (!emailRegex.test(email)) {
            window.emailInvalid = true;
            window.validatingEmail = false;
            emailInvalidGoBack();
        } else {
            window.emailInvalid = false;
        }
        
        // If regex passed
        if (!window.emailInvalid) {
            validateEmail(email);
        }
    }
}

async function validateEmail(email) {
    console.log('validating email');
    const url = "https://api.emailoversight.com/api/emailvalidation?apitoken=62689d38-3ee9-4e5f-9f95-756fcc64f377&listid=44420&email=" + email
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.log('Email Validation Failed:');
            console.log(response);
            window.emailInvalid = false;
            window.validatingEmail = false;
            emailValidContinue();
        }
    
        const json = await response.json();
        console.log(json);
        const emailResult = json.Result;
        
        if (validEmailTypes.indexOf(emailResult) === -1) {
            window.emailInvalid = true;
            window.validatingEmail = false;
            emailInvalidGoBack();
        } else {
            if (espInvalidTypes.indexOf(emailResult) === -1) {
                window.espValid = true;
            }
            window.emailInvalid = false;
            window.validatingEmail = false;
            emailValidContinue();
        }
    } catch (error) {
        console.log('Email Validation Failed:');
        console.error(error);
        window.emailInvalid = false;
        window.validatingEmail = false;
        emailValidContinue();
    }
}

async function phoneValidContinue() {
    let addressArrayValue = document.getElementById('address-clean').value;
    if (!addressArrayValue || addressArrayValue === "") {
        addressArrayValue = document.querySelectorAll("[data-variable='address']")[0].value;
    }
    // Make sure the user actually clicked the submit button already
    if (document.querySelectorAll(".submit").length !== -1) {
        console.log('begin array request');
        
        const url = "https://cw2pxa3ke236bfxks5pbcl5t2e0aysap.lambda-url.us-east-2.on.aws/"
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    firstName: document.querySelectorAll("[data-variable='firstname']")[0].value,
                    lastName: document.querySelectorAll("[data-variable='lastname']")[0].value,
                    address: {
                        street: addressArrayValue,
                        city: document.getElementById('city').value,
                        state: document.getElementById('state').value,
                        zip: document.querySelectorAll("[data-variable='zipcode']")[0].value
                    },
                    dob: document.querySelectorAll("[data-variable='year']")[0].value + '-' + document.querySelectorAll("[data-variable='month']")[0].value + '-' + document.querySelectorAll("[data-variable='day']")[0].value,
                    userConsent: {
                        ipAddress: document.getElementById('ipAddress').value,
                        timestamp: new Date().toISOString(),
                        url: document.getElementById('landingPageURL').value
                    }
                })
            });
    
            if (!response.ok) {
                console.log('Array Request Failed:');
                console.log(response);
            } else {
                // Parse the response only if it's successful
                const arrayResponse = await response.json();
                console.log('Array Response:', arrayResponse);
    
                // Check for valid status or process response
                if (arrayResponse && typeof arrayResponse === 'object') {
                    // Continue processing the response here
                    if (arrayResponse.hasOwnProperty('results')) { // if the object contains results
    					for (var key in arrayResponse.results) { // for each key in the results object
    						if (arrayResponse.results.hasOwnProperty(key) && arrayData.hasOwnProperty(key)) { // if arrayData has the same key
    							var result = arrayResponse.results[key] // store the data object to simplify
    							for (var resultKey in result) {
    								if (result.hasOwnProperty(resultKey)) {
    									if (result[resultKey]) {
    										arrayData[key] = resultKey
    									}
    								}
    							}
    						}
    					}
    				}
                } else {
                    console.log('No valid array response');
                }
            }
        } catch (error) {
            console.log('Array Request Failed:');
            console.error(error);
        }
        
        let arrayDebtResponse = arrayData.total_unsecured_debt;
    	let creditScore = arrayData.credit_score;
    	let collections = arrayData.collections;
    	let delinquencies = arrayData.delinquencies;
    
    	if (arrayDebtResponse) {
    		var lowEnd = 0;
    		var highEnd = 0;
    		var debtNumber;
    
    		if (arrayDebtResponse.indexOf('over') !== -1) {
    			// debt over number (+5k)
    			debtNumber = arrayDebtResponse.match(/\d.*/);
    			debtNumber = Number(debtNumber[0]);
    			console.log(debtNumber);
    			lowEnd = debtNumber;
    			highEnd = debtNumber + 5000;
    			verified_debt = (Number(lowEnd)+Number(highEnd))/2;
    		} else if (arrayDebtResponse.indexOf('under') !== -1) {
    			// debt under number (-5k)
    			debtNumber = arrayDebtResponse.match(/\d.*/);
    			debtNumber = Number(debtNumber[0]);
    			console.log(debtNumber);
    			lowEnd = debtNumber - 100;
    			highEnd = debtNumber;
    			verified_debt = (Number(lowEnd)+Number(highEnd))/2;
    		} else {
    			// debt is between numbers
    			debtNumber = arrayDebtResponse.match(/\d.*/);
    			console.log(debtNumber);
    			lowEnd = debtNumber[0].split('_')[0];
    			highEnd = debtNumber[0].split('_')[1];
    			verified_debt = Number(highEnd);
    		}
    	}
    
        document.getElementById('verifiedDebt').value = verified_debt;
        document.getElementById('arrayDebt').value = arrayDebtResponse;
        document.getElementById('arrayCredit').value = creditScore;
        document.getElementById('arrayCollections').value = collections;
        document.getElementById('arrayDelinquencies').value = delinquencies;
        
        
        // Now Continue to Submit to leadspedia!!!!
        submitLead();
    }
}

function phoneInvalidGoBack() {
    window.overridePhoneValidation = true;
    // If user has proceeded to validation screen, go back
    if (document.getElementById("button-a83d0248")) {
        document.getElementById("button-a83d0248").click()
    }
    
    // use a quick timeout to make sure the elements exist already
    window.setTimeout(function() {
        console.log(document.querySelectorAll("[data-variable='phone']")[0]);
        document.querySelectorAll("[data-variable='phone']")[0].closest('.input').classList.add('error');
    }, 300)
}

function startPhoneValidation(phone) {
    if (!window.validatingPhone) {
        window.validatingPhone = true;
        
        let phoneFormatted = phone;
        if (phone.startsWith('1 ')) {
            phoneFormatted = phoneFormatted.replace('1 ', '');
        }
        phoneFormatted = phoneFormatted.replaceAll(/\s/g, '');
        console.log(phoneFormatted)
        
        // first check character length
        if (phoneFormatted.length !== 10) {
            window.phoneInvalid = true;
            window.validatingPhone = false;
            phoneInvalidGoBack();
        } else {
            window.phoneInvalid = false;
        }
        
        // If passed
        if (!window.phoneInvalid) {
            validatePhone(phoneFormatted);
        }
    }
}

async function validatePhone(phoneFormatted) {
    console.log('validating phone');
    const url = "https://api.apilayer.com/number_verification/validate?number=1" + phoneFormatted
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "apikey":"4ykhNgxn0OzSYQYq1MW7hjJYXozN4rZt"
            }
        });
        if (!response.ok) {
            console.log('Phone Validation Failed:');
            console.log(response);
            window.phoneInvalid = false;
            window.validatingPhone = false;
        }
    
        const json = await response.json();
        console.log(json);
        const phoneResult = json;
        
        if ( (!phoneResult.valid) || (phoneResult.country_code !== 'US') ) {
            window.phoneInvalid = true;
            window.validatingPhone = false;
            phoneInvalidGoBack();
        } else {
            window.phoneInvalid = false;
            window.validatingPhone = false;
        }
    } catch (error) {
        console.log('Phone Validation Failed:');
        console.error(error);
        window.phoneInvalid = false;
        window.validatingPhone = false;
    }
    
    // store this for posting
    window.formattedPhoneNumber = phoneFormatted;
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace(),
    street_number = '',
    route = '',
    locality = '',
    administrative_area_level_1 = '',
    postal_code = '';

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (addressType === 'street_number') {
            street_number = place.address_components[i].short_name;
        } else if (addressType === 'route') {
            route = place.address_components[i].long_name;
        } else if (addressType === 'locality') {
            locality = place.address_components[i].long_name;
        } else if (addressType === 'administrative_area_level_1') {
            administrative_area_level_1 = place.address_components[i].short_name;
        } else if (addressType === 'postal_code') {
            postal_code = place.address_components[i].short_name;
        }
    }
    
    document.querySelectorAll("[data-variable='address']")[0].value = street_number + ' ' + route + ', ' + locality + ', ' + administrative_area_level_1;
    document.getElementById('address-clean').value = street_number + ' ' + route;
    document.querySelectorAll("[data-variable='zipcode']")[0].value = postal_code;
    document.getElementById('state').value = administrative_area_level_1;
    document.getElementById('city').value = locality;
    
    document.querySelectorAll("[data-variable='zipcode']")[0].closest('.input-box').classList.add('has-value');
    
    document.getElementById('id-fd0cb14d').click();
}

async function getLocationData() {
    console.log('getting location data');
    const url = "https://api.ipstack.com/check?access_key=df027d9a9416448d23a42df11a08961c"
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.log('Location Data Failed:');
            console.log(response);
        }
    
        const json = await response.json();
        console.log(json);
        const locationResult = json;
        
        document.getElementById('ipAddress').value = locationResult.ip;
    } catch (error) {
        console.log('Location Data Failed:');
        console.error(error);
    }
    
}

function setCookie(name,value,days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

async function submitLead() {
    let incomeValue = null;
    let incomeRadios = document.querySelectorAll("[data-variable='income']");
    for (const radioButton of incomeRadios) {
        if (radioButton.checked) {
            incomeValue = radioButton.dataset.answer;
            console.log(radioButton.dataset.answer);
            break;
        }
    }
    
    let phoneDataValue = window.formattedPhoneNumber;
    if (!phoneDataValue || phoneDataValue === "") {
        phoneDataValue = document.querySelectorAll("[data-variable='phone']")[0].value;
        if (phoneDataValue.startsWith('1 ')) {
            phoneDataValue = phoneDataValue.replace('1 ', '');
        }
        phoneDataValue = phoneDataValue.replaceAll(/\s/g, '');
    }

    let addressDataValue = document.getElementById('address-clean').value;
    if (!addressDataValue || addressDataValue === "") {
        addressDataValue = document.querySelectorAll("[data-variable='address']")[0].value;
    }
    
    const getPostKeys = {
		'0': {
			lp_campaign_id: '674a06cd2e45f',
			lp_campaign_key: 'vrTh3t6ZQbn2c7pKqzWF'
		},
		'efb': {
			lp_campaign_id: '675340428d124',
			lp_campaign_key: 'w2Fh8rvGZVgMY9j6zpdC'
		},
		'jfb': { // Pinterest
			lp_campaign_id: '674a06cd2e45f',
			lp_campaign_key: 'vrTh3t6ZQbn2c7pKqzWF'
		}
    }

    const data = {
        'lp_test': 0,
		'ip_address': document.getElementById('ipAddress').value,
		'aff_id': document.getElementById('affiliateID').value,
		'lp_s1': document.getElementById('s1').value,
		'lp_s2': document.getElementById('s2').value,
		'lp_s3': document.getElementById('s3').value,
		'lp_s4': document.getElementById('s4').value,
		'lp_s5': document.getElementById('s5').value,
		'user_agent': navigator.userAgent,
		'lp_response': "json",
		'landing_page': "https://firstadvantage.io/app/",
		'array_requested': true,
		'lp_campaign_id': getPostKeys[document.getElementById('affiliateID').value] ? getPostKeys[document.getElementById('affiliateID').value].lp_campaign_id : "674a06cd2e45f",
		'lp_campaign_key': getPostKeys[document.getElementById('affiliateID').value] ? getPostKeys[document.getElementById('affiliateID').value].lp_campaign_key : "vrTh3t6ZQbn2c7pKqzWF",
		'address': addressDataValue,
		'zip_code': document.querySelectorAll("[data-variable='zipcode']")[0].value,
		'city': document.getElementById('city').value,
		'state': document.getElementById('city').value,
		'email_address': document.querySelectorAll("[data-variable='email']")[0].value,
		'first_name': document.querySelectorAll("[data-variable='firstname']")[0].value,
		'last_name': document.querySelectorAll("[data-variable='lastname']")[0].value,
		'phone_home': phoneDataValue,
		'dob': document.querySelectorAll("[data-variable='month']")[0].value + '/' + document.querySelectorAll("[data-variable='day']")[0].value + '/' + document.querySelectorAll("[data-variable='year']")[0].value,
		'income': incomeValue,
		'debt_amount': Number(document.querySelectorAll("[data-variable='debtamount_num']")[0].value),
		'jornaya_lead_id': document.getElementById('leadid_token').value,
		'trusted_form_id': document.getElementsByName('xxTrustedFormToken')[0] ? document.getElementsByName('xxTrustedFormToken')[0].value : '',
		'array_collections': arrayData.collections,
		'array_credit': arrayData.credit_score,
		'array_delinquencies': arrayData.delinquencies,
		'array_debt': arrayData.total_unsecured_debt,
		'verified_debt': verified_debt
	}
	
	// Convert the data object into URL-encoded string
    const postData = new URLSearchParams(data).toString();
    
    try {
        const response = await fetch('https://bluemodomedia.leadspediatrack.com/post.do', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: postData
        });
        if (!response.ok) {
            console.log('Post lead failed');
            console.log(response);
        }
    
        const submitResult = await response.json();
        console.log(submitResult);
        
        postResult = submitResult.result;
        postMessage = submitResult.msg;
        postRevenue = Number(submitResult.price);
        
        if (postResult === 'success' && postMessage === 'Lead Accepted') {
            const clients = submitResult.clients;
			if (clients) {
				for (var i = 0; i < clients.length; i++) {
					if (clients[i].client.success === "true") {
						advertiserID = String(clients[i].client.advertiserID);
					}
				}
			}
			
			if (submitResult.redirect_url) {
			    if (submitResult.redirect_url.substring(0, 8) === 'https://') {
			        postRedirect = submitResult.redirect_url;
			    }
			}
        }

        
    } catch (error) {
        console.log('Post lead failed');
        console.log(error);
    }
    
    // For tracking, we can trigger a click on the hidden submit button
    if (document.querySelectorAll(".submit").length !== -1) {
        document.querySelectorAll(".submit")[0].click()
    }
}

// onload
window.addEventListener('heyflow-init', (event) => {
    console.log('onload');
    
    // kinda a hack way to load jornaya
    (function() {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.src = '//create.lidstatic.com/campaign/e3aff6c6-f632-eb4e-5fb3-e1f9d63c0fac.js?snippet_version=2';
        document.body.appendChild(s);
    })();
    
    // trusted form
    (function() {
        var field = 'xxTrustedFormCertUrl';
        var provideReferrer = false;
        var invertFieldSensitivity = false;
        var tf = document.createElement('script');
        tf.type = 'text/javascript'; tf.async = true; tf.defer = true;
        tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
          '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s); }
    )();

    // we should probably initialize custom inputs and values we'll need here
    const address = document.createElement('input');
    address.type = 'text';
    address.name = 'address-clean';
    address.id = 'address-clean';
    address.setAttribute('data-label', 'address-clean');
    address.value = "";
    address.style = "display:none;"

    const city = document.createElement('input');
    city.type = 'text';
    city.name = 'city';
    city.id = 'city';
    city.setAttribute('data-label', 'city');
    city.value = "";
    city.style = "display:none;"
    
    const state = document.createElement('input');
    state.type = 'text';
    state.name = 'state';
    state.id = 'state';
    state.setAttribute('data-label', 'state');
    state.value = "";
    state.style = "display:none;"
    
    const landingPageURL = document.createElement('input');
    landingPageURL.type = 'text';
    landingPageURL.name = 'landingPageURL';
    landingPageURL.id = 'landingPageURL';
    landingPageURL.setAttribute('data-label', 'landingPageURL');
    landingPageURL.value = "https://firstadvantage.io/app/";
    landingPageURL.style = "display:none;"
    
    const ipAddress = document.createElement('input');
    ipAddress.type = 'text';
    ipAddress.name = 'ipAddress';
    ipAddress.id = 'ipAddress';
    ipAddress.setAttribute('data-label', 'ipAddress');
    ipAddress.value = null;
    ipAddress.style = "display:none;"
    
    const verifiedDebtInput = document.createElement('input');
    verifiedDebtInput.type = 'text';
    verifiedDebtInput.name = 'verifiedDebt';
    verifiedDebtInput.id = 'verifiedDebt';
    verifiedDebtInput.setAttribute('data-label', 'verifiedDebt');
    verifiedDebtInput.value = "";
    verifiedDebtInput.style = "display:none;"
    
    const arrayDebt = document.createElement('input');
    arrayDebt.type = 'text';
    arrayDebt.name = 'arrayDebt';
    arrayDebt.id = 'arrayDebt';
    arrayDebt.setAttribute('data-label', 'arrayDebt');
    arrayDebt.value = "";
    arrayDebt.style = "display:none;"
    
    const arrayCollections = document.createElement('input');
    arrayCollections.type = 'text';
    arrayCollections.name = 'arrayCollections';
    arrayCollections.id = 'arrayCollections';
    arrayCollections.setAttribute('data-label', 'arrayCollections');
    arrayCollections.value = "";
    arrayCollections.style = "display:none;"
    
    const arrayCredit = document.createElement('input');
    arrayCredit.type = 'text';
    arrayCredit.name = 'arrayCredit';
    arrayCredit.id = 'arrayCredit';
    arrayCredit.setAttribute('data-label', 'arrayCredit');
    arrayCredit.value = "";
    arrayCredit.style = "display:none;"
    
    const arrayDelinquencies = document.createElement('input');
    arrayDelinquencies.type = 'text';
    arrayDelinquencies.name = 'arrayDelinquencies';
    arrayDelinquencies.id = 'arrayDelinquencies';
    arrayDelinquencies.setAttribute('data-label', 'arrayDelinquencies');
    arrayDelinquencies.value = "";
    arrayDelinquencies.style = "display:none;"
    
    const affiliateID = document.createElement('input');
    affiliateID.type = 'text';
    affiliateID.name = 'affiliateID';
    affiliateID.id = 'affiliateID';
    affiliateID.setAttribute('data-label', 'affiliateID');
    affiliateID.value = params.has('aid') ? params.get('aid') : getCookie('aff_id') ? getCookie('aff_id') : "0";
    affiliateID.style = "display:none;"
    
    const jornayaLeadId = document.createElement('input');
    jornayaLeadId.type = 'hidden';
    jornayaLeadId.name = 'universal_leadid';
    jornayaLeadId.id = 'leadid_token';
    jornayaLeadId.setAttribute('data-label', 'jornayaLeadId');
    jornayaLeadId.value = "";
    jornayaLeadId.style = "display:none;"
    
    const s1 = document.createElement('input');
    s1.type = 'text';
    s1.name = 's1';
    s1.id = 's1';
    s1.setAttribute('data-label', 's1');
    s1.value = params.has('s1') ? params.get('s1') : params.has('sub1') ? params.get('sub1') : "";
    s1.style = "display:none;"
    
    const s2 = document.createElement('input');
    s2.type = 'text';
    s2.name = 's2';
    s2.id = 's2';
    s2.setAttribute('data-label', 's2');
    s2.value = params.has('s2') ? params.get('s2') : params.has('sub2') ? params.get('sub2') : "";
    s2.style = "display:none;"
    
    const s3 = document.createElement('input');
    s3.type = 'text';
    s3.name = 's3';
    s3.id = 's3';
    s3.setAttribute('data-label', 's3');
    s3.value = params.has('s3') ? params.get('s3') : params.has('sub3') ? params.get('sub3') : "";
    s3.style = "display:none;"
    
    const s4 = document.createElement('input');
    s4.type = 'text';
    s4.name = 's4';
    s4.id = 's4';
    s4.setAttribute('data-label', 's4');
    s4.value = params.has('s4') ? params.get('s4') : params.has('sub4') ? params.get('sub4') : "";
    s4.style = "display:none;"
    
    const s5 = document.createElement('input');
    s5.type = 'text';
    s5.name = 's5';
    s5.id = 's5';
    s5.setAttribute('data-label', 's5');
    s5.value = params.has('s5') ? params.get('s5') : params.has('sub5') ? params.get('sub5') : "";
    s5.style = "display:none;"
    
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(city);
    form.appendChild(state);
    form.appendChild(address);
    form.appendChild(landingPageURL);
    form.appendChild(ipAddress);
    form.appendChild(verifiedDebtInput);
    form.appendChild(arrayDebt);
    form.appendChild(arrayCollections);
    form.appendChild(arrayCredit);
    form.appendChild(arrayDelinquencies);
    form.appendChild(affiliateID);
    form.appendChild(jornayaLeadId);
    form.appendChild(s1);
    form.appendChild(s2);
    form.appendChild(s3);
    form.appendChild(s4);
    form.appendChild(s5);

    const waitForGoogle = window.setInterval(function() {
		console.log(google);
		if (google) {
			clearInterval(waitForGoogle);
			autocomplete = new google.maps.places.Autocomplete(document.querySelectorAll("[data-variable='address']")[0], {types: ['geocode']});
            autocomplete.setFields(['address_component']);
            autocomplete.addListener('place_changed', fillInAddress);
            autocomplete.setComponentRestrictions({'country': ['us', 'pr', 'vi', 'gu', 'mp']});
		}
	}, 500);
    
});

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail);
    if (event.detail.screenName === "email-validation") {
        let continueButton = document.getElementById('button-a04b3450');
        let backButton = document.getElementById('button-2729fcaa');
        if (event.detail.previousScreenName !== 'email') {
            backButton.click();
        } else if (!window.validatingEmail && !window.emailInvalid) {
            // email was already validated - proceed
            continueButton.click()
        } else if (!window.validatingEmail && window.emailInvalid) {
            // email was already invalidated - go back
            emailInvalidGoBack();
        }
    } else if (event.detail.screenName === "email") {
        if (!emailEvents) {
            document.querySelectorAll("[data-variable='email']")[0].addEventListener('keyup', function(e) {
                // console.log(e.target.value);
                if (emailRegex.test(e.target.value)) {
                    document.querySelectorAll("[data-variable='email']")[0].closest('.input').classList.remove('error');
                }
            });
            document.querySelectorAll("[data-variable='email']")[0].addEventListener('keydown', function(e) {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    startEmailValidation(e.target.value);
                }
            });
            emailEvents = true;
        }
    } else if (event.detail.screenName === "phone-validation") {
        let backButton = document.getElementById('button-a83d0248');

        if (event.detail.previousScreenName !== 'phone') {
            backButton.click();
        } else if (window.validatingPhone) {
            // still validating - wait for it to finish
            const waitForPhoneValidation = setInterval(function() {
                if (!window.validatingPhone) {
                    clearInterval(waitForPhoneValidation);
                    if (!window.phoneInvalid) {
                        // proceed with Array / Leadspedia
                        phoneValidContinue();
                    } else {
                        // phone was invalidated - go back
                        phoneInvalidGoBack();
                    }
                }
            }, 500);
        } else if (!window.validatingPhone && !window.phoneInvalid) {
            // phone was already validated - proceed with Array / Leadspedia
            phoneValidContinue();
        } else if (!window.validatingPhone && window.phoneInvalid) {
            // phone was already invalidated - go back
            phoneInvalidGoBack();
        }
    } else if (event.detail.screenName === "phone") {
        if (!phoneEvents) {
            document.querySelectorAll("[data-variable='phone']")[0].addEventListener('keyup', function(e) {
                let targetPhoneValue = e.target.value;
                if (targetPhoneValue.startsWith('1 ')) {
                    targetPhoneValue = targetPhoneValue.replace('1 ', '');
                }
                targetPhoneValue = targetPhoneValue.replaceAll(/\s/g, '');
                if (targetPhoneValue.length === 10) {
                    document.querySelectorAll("[data-variable='phone']")[0].closest('.input').classList.remove('error');
                }
            });
            document.querySelectorAll("[data-variable='phone']")[0].addEventListener('keydown', function(e) {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    startPhoneValidation(e.target.value);
                }
            });
            phoneEvents = true;
        }
    } else if (event.detail.screenName === "name") {
        // Get location data here
        if (!document.getElementById('ipAddress').value) {
            getLocationData();
        }
    } else if (event.detail.screenName === "dob") {
        if (!dobEvents) {
            document.querySelectorAll("[data-variable='month']")[0].addEventListener('keyup', function(e) {
                if (isFinite(e.key) && e.target.value.length === 2) {
                    document.querySelectorAll("[data-variable='day']")[0].focus();
                }
            });
            
            document.querySelectorAll("[data-variable='month']")[0].addEventListener('keydown', function(e) {
                if (e.which === 9) {
                    if (e.target.value.length !== 2) {
                        e.preventDefault()
                        return false
                    }
                } else if (isFinite(e.key)) { // a number was entered
                    if (e.target.value.length === 0) {
                        // first number entered
                        if (Number(e.key) >= 2) {
                            // add a zero and move to year field
                            e.preventDefault()
                            document.querySelectorAll("[data-variable='month']")[0].value = "0" + e.key.toString()
                            document.querySelectorAll("[data-variable='day']")[0].focus()
                        }
                    }
                }
            });
            
            document.querySelectorAll("[data-variable='day']")[0].addEventListener('keyup', function(e) {
                if (isFinite(e.key) && e.target.value.length === 2) {
                    document.querySelectorAll("[data-variable='year']")[0].focus();
                }
            });
            
            document.querySelectorAll("[data-variable='day']")[0].addEventListener('keydown', function(e) {
                if (e.which === 9) {
                    if (e.target.value.length !== 2) {
                        e.preventDefault()
                        return false
                    }
                } else if (isFinite(e.key)) { // a number was entered
                    if (e.target.value.length === 0) {
                        // first number entered
                        if (Number(e.key) >= 4) {
                            // add a zero and move to year field
                            e.preventDefault()
                            document.querySelectorAll("[data-variable='day']")[0].value = "0" + e.key.toString()
                            document.querySelectorAll("[data-variable='year']")[0].focus()
                        }
                    }
                }
            });
            
            document.querySelectorAll("[data-variable='year']")[0].addEventListener('keydown', function(e) {
              if (e.target.value.length === 0 && isFinite(e.key)) {
                    if (Number(e.key) > 2) {
                        e.preventDefault()
                    }
                }
            });
        }
    } else if (event.detail.screenName === "thankyou") {
        // #advertiserName
        // #advertiserDetail
        // #advertiserPhone
        if (advertiserMapping[advertiserID]) {
            if (advertiserMapping[advertiserID].did !== null) {
                let newHTML = `
                    <strong>For priority support and to see how much you could save, call 
                    <a href="tel:${advertiserMapping[advertiserID].did.replace(/-/g, '')}" class="text-link">${advertiserMapping[advertiserID].did}</a> 
                    and a ${advertiserMapping[advertiserID].buyer} support agent will be able to help you immediately.</strong>
                `;
                document.getElementById('advertiserDetail').innerHTML = newHTML;
                document.getElementById('advertiserPhone').href = 'tel:' + advertiserMapping[advertiserID].did.replace(/-/g, '');
                document.getElementById('advertiserPhone').style.display = "block";
            } else {
                document.getElementById('advertiserName').textContent = advertiserMapping[advertiserID].buyer;
            }
        }
    }
});

window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow change:', event.detail);
    if (event.detail.screenName === "email" && event.detail.fieldsSimple.Email) {
        let email = event.detail.fieldsSimple.Email;
        startEmailValidation(email);
    } else if (event.detail.screenName === "phone" && event.detail.fieldsSimple.Phone) {
        let phone = event.detail.fieldsSimple.Phone;
        startPhoneValidation(phone);
    }
});

window.addEventListener('heyflow-submit', (event) => {
    console.log('heyflow submit:', event.detail);
    
    console.log(advertiserMapping[advertiserID]);
    console.log(postRevenue);
    
    // 3 potential scenarios
    // 1: redirect to returned buyer redirect URL
    // 2: redirect to our thank you page for buyers
    // 3: redirect to offer wall
    
    if (postRedirect !== '') { // Scenario 1
        window.location.href = postRedirect;
    } else if (advertiserMapping[advertiserID] && postRevenue > 1) { // Scenario 2
        window.setTimeout(function() {
            document.getElementById('button-ad19b9b3').click();
        }, 300)
    } else { // Scenario 3
        window.location.href = "https://firstadvantage.io/programs/save.html?vd=" + verified_debt.toString();
    }
    
})